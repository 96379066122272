import { useEffect } from 'react';

/**
 * useKeyPress
 * https://www.caktusgroup.com/blog/2020/07/01/usekeypress-hook-react/
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
 export default function useKeypress(key, action) {
    useEffect(() => {
        function onKeyup(e) {
            if (e.keyCode === key) action()
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);
  }, ); // used to be "}, []);" this was removed because the [] was said to not be needed (error on console). 
}