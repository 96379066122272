//https://github.com/usb-radiology/react-cornerstone-viewport-hooks#readme
import React, { useState, useEffect, useRef } from "react";

import * as cornerstone from "cornerstone-core";
import * as cornerstoneMath from "cornerstone-math";
import * as cornerstoneTools from "cornerstone-tools";
import Hammer from "hammerjs"; //CAN USE THIS FOR TOUCH SCREEN
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";

import JSZip from "jszip"
import { saveAs } from 'file-saver';

import '../index.css'

// More examples of setters for the segmentations module are found here: 
// https://github.com/cornerstonejs/cornerstoneTools/issues/1296
// The actual location of these functions is here:
// https://github.com/cornerstonejs/cornerstoneTools/blob/master/src/store/modules/segmentationModule/index.js
const { getters, setters } = cornerstoneTools.getModule('segmentation')



const viewerX = 512
const viewerY = 512 
const tableauLUT = [
    [0,     0,   0,   0],  // black for background - make it completely see through (alpha = 0)
    [31,  119, 180, 255],  // blue
    [255, 127,  14, 255],  // orange
    [44,  160,  44, 255],  // green
    [214,  39,  40, 255],  // red
    [148, 103, 189, 255],  // purple
]

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWebImageLoader.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;

cornerstoneTools.init();


cornerstoneWebImageLoader.configure({
    beforeSend: function(xhr) {
        // Add custom headers here (e.g. auth tokens)
        //xhr.setRequestHeader('x-auth-token', 'my auth token');
    }
});

cornerstone.registerImageLoader('data', cornerstoneWebImageLoader.loadImage);

// REMOVED ALL TOOLS BECAUSE THEY ARE NOT NEEDED FOR THIS APPLICATION - IMAGES SCALED TO FIT SCREEN
// Should / will use these in the future for medical image stuff. 

// Grab Tool Classes
// const WwwcTool = cornerstoneTools.WwwcTool;
const PanTool = cornerstoneTools.PanTool;
const PanMultiTouchTool = cornerstoneTools.PanMultiTouchTool;
// const ZoomTool = cornerstoneTools.ZoomTool;
const ZoomTouchPinchTool = cornerstoneTools.ZoomTouchPinchTool;
const ZoomMouseWheelTool = cornerstoneTools.ZoomMouseWheelTool;
const FreehandScissorsTool = cornerstoneTools.FreehandScissorsTool;

// Tools in general are here: https://github.com/cornerstonejs/cornerstoneTools/tree/master/src/tools
// Segmentation tools are here: https://github.com/cornerstonejs/cornerstoneTools/tree/master/src/tools/segmentation

const listTools = [
    {tool: FreehandScissorsTool, properties: { mouseButtonMask: 1 }, name: 'FreehandScissors'},

    // {tool: ZoomTool, properties: { mouseButtonMask: 2 }}, 
    // {tool: WwwcTool, properties: { mouseButtonMask: 1 }},
    {tool: PanTool, properties: { mouseButtonMask: 1}, name: 'Pan'}, 
    {tool: PanMultiTouchTool, properties: {}, name: 'PanMultiTouch'}, 
    {tool: ZoomTouchPinchTool, properties: {}, name: 'ZoomTouchPinch'}, 
    {tool: ZoomMouseWheelTool, properties: {}, name: 'ZoomMouseWheel'}
]

const addToolsForElement = (element, listTools) => {
    const validToolNames = getValidToolNames(element) // tools that are already added to the element. 
    console.log('Adding list tools:', listTools)
    for (const toolObject of listTools) {
        const tool = toolObject.tool
        const name = toolObject.name

        if (validToolNames.includes(name)) {
          continue;
        }

        console.log(`Added Tool: ${name}`)
        cornerstoneTools.addToolForElement(
            element,
            tool,
        );
    }
}

const enableToolsElement = (element, listTools) => {
    const validToolNames = getValidToolNames(element)
    console.log('Enabling/activating list tools', listTools)
    for (const toolObject of listTools) {
        // const tool = toolObject.tool
        const name = toolObject.name
        const properties = toolObject.properties
        // const name = tool.name.substr(0, tool.name.length-4)
        // const name = tool.name

        if (!validToolNames.includes(name)) {
            console.warn(
              `Trying to set a tool (${name}) active that is not "added". Available tools include: ${validToolNames.join(
                ", ",
              )}`,
            );
        }
        cornerstoneTools.setToolActive(name, properties);
    }
}

const divStyle = {
  width: `${viewerX}px`,
  height: `${viewerY}px`,
//   position: "relative",
  color: "white",
  borderStyle: 'solid',
  borderColor: 'black'
};

const getImageContainer = (viewportElementRef) => {
    return(

        <div className="viewportElement"
            style={divStyle}
            ref={viewportElementRef}
            >
            <canvas className="cornerstone-canvas" />
            {/* <div id="dicomImage"
                style={imageStyle}>
            </div> */}
        </div>
    )
    
  }

const getValidToolNames = function (enabledElement) {
  const validTools = cornerstoneTools.store.state.tools.filter(
    (tool) => tool.element === enabledElement,
  );
  const validToolNames = validTools.map((tool) => tool.name);
  return validToolNames;
};

const updateSegmentations = async (element, imageIdx, segmentations, setSegmentations, h, w) => {
    return new Promise(function(resolve, reject) {
    
    const { labelmap2D, labelmap3D } = getters.labelmap2D(element)
    console.log('labelmap3d', labelmap3D)
    console.log('labelmap2d', labelmap2D)
    labelmap2D.height = h
    labelmap2D.width = w
    
    let newSegs = {...segmentations, [imageIdx]: labelmap2D}
    setSegmentations(newSegs)
    console.log(`Updated segmentation: ${imageIdx}`)
    resolve(newSegs) // pass updated segmentations to response. So dont have problems waiting for setSegmentations to work
    })
}

const createImageFromBuffer = (buffer, height, width) => {
    // create a canvas to store the image data
    var canvas = document.createElement('canvas'),
        ctx = canvas.getContext("2d");
    // set the canvas size
    canvas.width = width
    canvas.height = height

    // create imageData object
    var imageData = ctx.createImageData(width, height)
    // set buffer as the source of the imageData
    imageData.data.set(buffer)
    
    // update the canvas with the new data
    ctx.putImageData(imageData, 0, 0)

    var segURL = canvas.toDataURL() // produce PNG file

    return (segURL)
}

const labelmap2DToBuffer = (labelmap2D, LUT=tableauLUT) => {
    // https://stackoverflow.com/questions/22823752/creating-image-from-array-in-javascript-and-html5
    // unpack a segmentation array into an image buffer
    let height = labelmap2D.height, 
        width = labelmap2D.width,
        buffer = new Uint8ClampedArray(width * height * 4) // 4 = RGBA

    for (let y = 0; y < height; y++){
        for (let x = 0; x < width; x++) {
            let segPos = y * width + x
            let imagePos = segPos * 4 // get position in buffer
            let pixelVal = labelmap2D.pixelData[segPos]

            let [R, G, B, A] = LUT[pixelVal]
            
            buffer[imagePos] = R; // R [0, 255]
            buffer[imagePos + 1] = G; // R [0, 255]
            buffer[imagePos + 2] = B; // R [0, 255]
            buffer[imagePos + 3] = A; // R [0, 255]
        }
    }
    return (buffer)
}

const convertLabelmap2DToImageURL = (labelmap2D) => {
    let buffer = labelmap2DToBuffer(labelmap2D)
    let segURL = createImageFromBuffer(buffer, labelmap2D.height, labelmap2D.width)
    return (segURL)
}

const downloadSegmentations = (segmentations, imageURLs) => {
    let zip = new JSZip();
    const folder = zip.folder("segmentations");

    for (const segIdx in segmentations){
        let seg = segmentations[segIdx]
        let segName = imageURLs[segIdx].filename
        let segURL = convertLabelmap2DToImageURL(seg)
        segURL = segURL.split('base64,')[1]
        folder.file(segName + '_seg.png', segURL, {base64: true})
    }


    zip.generateAsync({type:"blob"})
    .then(response => {
        saveAs(response, 'segmentations.zip')
    })
}


const CornerstoneViewer = ({
    imageURLs = [],
    imageIdx = 0,
    downloadData = false,
    setDownloadData,
    activeSegmentIdx=1,
    undo=false,
    setUndo,
    redo=false,
    setRedo,
    eraseOn=false,
    isPanActive=false,
    isSegmentActive=true,
    toggleOpacity=false,
    setToggleOpacity,
    isNewImages=false,
    setIsNewImages,

}) => {

    const viewportElementRef = useRef(null);
    const [imageData, setImageData] = useState(imageURLs)
    const [isElementEnabled, setIsElementEnabled] = useState(false);
    const [imageIdxState, setImageIdxState] = useState(imageIdx);
    const [segmentations, setSegmentations] = useState({});
    const [imageHeight, setImageHeight] = useState(0);
    const [imageWidth, setImageWidth] = useState(0);
    const [imagesDownloaded, setImagesDownloaded] = useState(true);

    // useEffect(() => {        
    //     console.log(`Starting new render:`, )
    //     console.log(`imageIdx:    ${imageIdx};    imageIdxState:    ${imageIdxState}`)
    //     console.log(`imageHeight:    ${imageIdxState};    imageWidth:    ${imageWidth}`)
    // }, [imageHeight, imageIdx, imageIdxState, imageWidth, randInt, setRandInt])

    // Identify if image data was updated. 
    // If so, update imagesDownloaded parameters
    // This allows keeping track of statuses of if we have new data and if that 
    // data has been downloaded 
    useEffect(() => {
        if (imageURLs.length > 0){
            if (imageURLs !== imageData){
                setImageData(imageURLs)
                setImagesDownloaded(false)
            }
        }
    }, [imageURLs, imageData, setImageData, setImagesDownloaded, isNewImages, imageIdx, imageIdxState, setIsNewImages])

    // 
    // Save segmentations
    // POTENTIAL RESOURCE FOR DEALING WITH SEGMENTATIONS: 
    // https://github.com/dcmjs-org/dcmjs/blob/master/examples/createSegmentation/index.html
    useEffect(() => {
        // all of this is done using the old imageIdxState & the height/width before later
        // useEffect functions change these parameters
        const element = viewportElementRef.current;
        if (imageIdx !== imageIdxState){ // did the imageIdx change? 
            const labelmap2D = getters.labelmap2D(element)
            if (typeof labelmap2D !== 'undefined' && labelmap2D !== null){
                console.log('Save segmentation before going to next image')
                updateSegmentations(element, imageIdxState, segmentations, setSegmentations, imageHeight, imageWidth)
                .then(updatedSegs => {
                    console.log('Updated sementation objects: ', updatedSegs)
                })
            }
        }
    }, [imageIdx, imageIdxState, segmentations, imageHeight, imageWidth, setSegmentations])

    // 
    // Download segmentations if requested & they havent been downloaded yet. 
    //
    useEffect(() => {
        if (downloadData === true && imagesDownloaded === false){
            // save the current imageIdx just in case it hasnt been saved yet. 
            const element = viewportElementRef.current;
            updateSegmentations(element, imageIdxState, segmentations, setSegmentations, imageHeight, imageWidth)
            .then(updatedSegs => {
                downloadSegmentations(updatedSegs, imageURLs)
            })
            setDownloadData(false)
            setImagesDownloaded(true)
            
        }else if(downloadData === true && imagesDownloaded === true){
            // incase we tried to download the data again but we have alreaady downloaded it
            // then reset the download data parameter. 
            setDownloadData(false)
        }
    }, [
        viewportElementRef,
        imageIdxState,
        imageHeight,
        imageWidth,
        downloadData, 
        imagesDownloaded, 
        segmentations,
        imageURLs,
        setSegmentations,
        setImagesDownloaded,
        setDownloadData,
    ])

    useEffect(() => {

    })

    //
    // undo last segmentation edit
    //
    useEffect(() => {
        if (imageURLs.length !== 0 && undo === true){
            const element = viewportElementRef.current;
            setters.undo(element)
            setUndo(false)
        }
    }, [imageURLs, undo, viewportElementRef, setUndo])

    //
    // redo last segmentation edit
    //
    useEffect(() => {
        if (imageURLs.length !== 0 && redo === true){
            const element = viewportElementRef.current;
            setters.redo(element)
            setRedo(false)
        }
    }, [imageURLs, redo, viewportElementRef, setRedo])


    //
    // initialize element
    //
    useEffect(() => {
        console.log('Initializing the element')
        const element = viewportElementRef.current;
        cornerstone.enable(element);

        cornerstoneTools.addStackStateManager(element, ["stack"]);
        setIsElementEnabled(true);

        return () => {
        cornerstone.disable(element);
        };
    }, [viewportElementRef, setIsElementEnabled]); // only initialize if the element changes. 

    //
    // settting up stack
    //
    useEffect(() => {
        const element = viewportElementRef.current;
        if (imageURLs.length !== 0){
            console.log('Adding Tool State Stack')
            cornerstoneTools.clearToolState(element, "stack");
            cornerstoneTools.addToolState(element, "stack", {
                imageIds: [imageURLs[imageIdx].url,],
                currentImageIdIndex: 0, // just start at 0
            });        
        } 
    }, [imageURLs, imageIdx, viewportElementRef]); // only update stack if imageURLs or imageIdx or viewport changes. 

    //
    // add and activating/enabling tools
    //
    useEffect(() => {
        const element = viewportElementRef.current;
        if (isElementEnabled) {
            console.log(`Adding tools to element`)
            addToolsForElement(element, listTools)
            enableToolsElement(element, listTools);
        }
        return () => {
        };
    }, [viewportElementRef, isElementEnabled]); // only enable tools if the viewport of the isElementEnabled status changes. 
    
    //
    // load new image, set activeSegment, set draw or erase
    //
    // Alternative to current implementtion... Should only reload image if new image. 
    // Then do all of the other steps after that step and do them in a separate promise chain
    // if new image => reload + image settings update
    // else if no new image => image settings update
    //
    useEffect(() => {
        // if (isNewImages || imageIdxState !== imageIdx){
        if (imageURLs.length > 0){
            const imageURL = imageURLs[imageIdx].url
            const element = viewportElementRef.current;
            
            if (isNewImages || imageIdxState !== imageIdx){
                cornerstone.enable(element)
            }
            
            cornerstone.loadAndCacheImage(imageURL) // caching (instead of just .loadImage()) helps with continuity and user experience. 
            .then(function(image) {
                cornerstoneTools.addStackStateManager(element, ['stack'])
                cornerstoneTools.addToolState(element, "stack", {
                    imageIds: [imageURLs[imageIdx].url,],
                    currentImageIdIndex: 0, // just start at 0
                })
                cornerstone.displayImage(element, image);
                setImageHeight(image.height)
                setImageWidth(image.width)

                return(image)
            })
            .then(image => {
                // if the zoom hasnt been set, and the image isnt the
                // same as it was before, then reset the zoom.
                if (isNewImages || imageIdxState !== imageIdx){
                    const viewport = cornerstone.getViewport(element)
                    console.log('Resetting zoom', 'viewport', viewport)
                    // update the viewport for the newly loaded image
                    viewport.displayedArea.brhc.x = image.width
                    viewport.displayedArea.brhc.y = image.height

                    const xScale =  viewerX / image.width
                    const yScale =  viewerY / image.height
                    const scale = Math.min(xScale, yScale)

                    viewport.scale = scale

                    cornerstone.setViewport(element, viewport)
                }
            })
            .then(() => {
                // set the active segment
                console.log(`Setting active segment to: ${activeSegmentIdx}`)
                setters.activeSegmentIndex(element, activeSegmentIdx)
            })
            .then(() => {
                // get the labelmap to ensure it is "activated"
                // this is necessary to let the user be able to update the 
                // segmentation label
                const labelmap2D = getters.labelmap2D(element);
                console.log(labelmap2D);
            })
            .then(() => {
                // print the active segment to make sure changing it worked properly. 
                const activeSeg = getters.activeSegmentIndex(element)
                console.log('Active segment is: ', activeSeg)
            })
            .then(() => {
                const freehandScissorsTool = cornerstoneTools.getToolForElement(
                    element,
                    'FreehandScissors'
                );
                if (eraseOn === true){
                    console.log('Freehand Scissors Tool set to Erase')
                    freehandScissorsTool.setActiveStrategy('ERASE_INSIDE')
                }else if (eraseOn === false) {
                    console.log('Freehand Scissors Tool set to Draw')
                    freehandScissorsTool.setActiveStrategy('FILL_INSIDE')
                }
            })
            .then(() => {
                if (toggleOpacity){
                    let colorLUT = getters.activeLabelmapBuffer(element).colorLUT
                    let segmentColor = colorLUT[activeSegmentIdx]
                    const opacity = segmentColor[3]
                    segmentColor[3] = opacity === 255 ? 0 : 255
                    colorLUT[activeSegmentIdx] = segmentColor
                    // colorLUT[activeSegmentIdx][3] = newOpacity
                    setters.colorLUT(activeSegmentIdx, colorLUT.slice(1))
                    setToggleOpacity(!toggleOpacity)
                    console.log(`Opacity toggled on/off for segment: ${activeSegmentIdx}`)
                }
            })
            .then(() => {
                // all of the logic that needs to know if loaded new images has been completed
                // so we not reset the "isNewImages" to false. This will reset once per set of new images 
                setIsNewImages(false) 
            })
            .catch(error => {
                console.log('error', error)
            })            
        }
    }, [
        imageURLs, 
        imageIdx,
        viewportElementRef,
        setImageHeight,
        setImageWidth,
        activeSegmentIdx,
        eraseOn,
        toggleOpacity,
        setToggleOpacity,
        isNewImages,
        imageIdxState,
        setIsNewImages,
    ])

    //
    // Toggle between segmenting and panning
    //
    useEffect(() => {
        const element = viewportElementRef.current;
        if (isElementEnabled && isPanActive) {
            enableToolsElement(element, [listTools[1],])
        }else if (isElementEnabled && isSegmentActive){
            enableToolsElement(element, [listTools[0],])
        }
      }, [viewportElementRef, isElementEnabled, isPanActive, isSegmentActive])
    
    // set the image idx state. 
    useEffect(() => {
        setImageIdxState(imageIdx)
    },[imageIdx]) // only update if the imageIdx changes. 

    return(
        <div>
            {getImageContainer(viewportElementRef)}
            <div>
              {(imageData.length > 0) 
                ? 
                    <button 
                        onClick={() => {setImagesDownloaded(false)}}
                        type='button'
                        className="btn btn-warning"
                        style={{margin: '5px'}}
                    >
                        Enable Additional Downloads
                    </button>
                : ''
              }
            </div>
        </div>
        
    )
}

export default CornerstoneViewer
